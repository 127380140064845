@tailwind base;
@tailwind components;
@tailwind utilities;

html{scroll-behavior:smooth}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  margin-left: -11px;
  padding-left: 1px;
  padding-right: 1px;
  overflow: visible;
  width: 16px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}